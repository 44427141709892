:root {
    --mdc-theme-primary: #1592d8 !important;
}

.mdc-floating-label--float-above {
    color: #1592d8 !important;
}

body,
#root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    touch-action: none;
    user-select: none;
}

main {
    width: calc(100% - 2rem);
    display: flex;
    justify-content: center;
    padding: 1rem;
    position: fixed;
    touch-action: none;
}

main section {
    width: calc(100% - 2rem);
    max-width: 640px;
    height: 100%;
    overflow: auto;
    touch-action: none;
}

.mdc-top-app-bar {
    background-color: #1592d8 !important;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    top: 0px;
    right: 0px;
    left: 0px;
}

.mdc-tab-bar {
    background-color: #1592d8 !important;
    color: rgb(255, 255, 255) !important;
    box-shadow: 0px 2px 4px -2px rgba(0,0,0,.5);    
}

.mdc-tab-bar * {
    color: rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) !important;
}
